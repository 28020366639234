<template>
  <div class="agent-wrap">
    <Header :title="$t('agent.manageResource')" :subTitle="$t('common.add')">
      <ChangeAvatar :headPhoto="userInfo.headPhoto" :setHeadPhoto="setHeadPhoto" />
      <!-- <ChangeBg /> -->
    </Header>

    <div class="agent-info-box">

      <!-- 昵称 -->
      <div class="info-item" @click="editNickName">
        <div class="item-left"><span class="red">*</span>{{ $t('userDetail.nickName') }}</div>
        <div class="item-right">
          <div> {{ userInfo.nickname }}</div>
          <div>
            <img src="@/assets/img/icon/arrow.svg" />
          </div>
        </div>
      </div>

      <!-- 位置 -->
      <div class="info-item" @click="eidtLocation('position')">
        <div class="item-left"><span class="red">*</span>{{ $t('userDetail.location') }}</div>
        <div class="item-right">
          <div>{{ position && (`${position.province.name}/${position.city.name}/${position.area.name}`) }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- Telegram -->
      <div class="info-item" @click="editTelegram">
        <div class="item-left">Telegram</div>
        <div class="item-right">
          <div>{{ userInfo.telegram }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 微信 -->
      <div class="info-item" @click="editWechat">
        <div class="item-left">{{ $t('userDetail.wechat') }}</div>
        <div class="item-right">
          <div>{{ userInfo.wechat }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 生日 -->
      <div class="info-item" @click="editAge">
        <div class="item-left">{{ $t('userDetail.age') }}</div>
        <div class="item-right">
          <div>{{ userInfo.age }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 身高体重 -->
      <div class="info-item" @click="editHeightWeight">
        <div class="item-left">{{ $t('userDetail.heightAndWeight') }}</div>
        <div class="item-right">
          <div>{{ userInfo.height }}<span v-if="userInfo.height">cm &nbsp;&nbsp;</span> {{ userInfo.weight }}<span
              v-if="userInfo.weight">kg</span></div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>
    
      <!-- 自我介绍 -->
      <div class="info-item" @click="eidtDescription">
        <div class="item-left">{{ $t('userDetail.selfDescription') }}</div>
        <div class="item-right">
          <div>{{ userInfo.description }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 行业 -->
      <div class="info-item" @click="editJob">
        <div class="item-left">{{ $t('userDetail.career') }}</div>
        <div class="item-right">
          <div>{{ userInfo.job }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 气质类型 -->
      <div class="info-item" @click="editTemperament">
        <div class="item-left">{{ $t('userDetail.temperamentType') }}</div>
        <div class="item-right">
          <div>{{ userInfo.temperament }}</div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>

      <!-- 经常出没 -->
      <div class="info-item" @click="eidtLocation('often', 3, true)">
        <div class="item-left">{{ $t('userDetail.offen') }}</div>
        <div class="item-right">
          <div>
            <span v-for="(item, index) in userInfo.oftenList" :key="index">
              {{ item }}
              <span v-if="index + 1 !== userInfo.oftenList.length">、</span>
            </span>
          </div>
          <div><img src="@/assets/img/icon/arrow.svg"  alt=""/></div>
        </div>
      </div>
    </div>

    <div class="btn" @click.stop="saveAgent">{{ $t('common.confirm') }}</div>

    <!--昵称编辑-->
    <TextInput v-model="showNickName" :title="$t('userDetail.nickName')" :initMsg="userInfo.nickname" :placeholder="$t('userDetail.nickNameHolder')"
      @close="closeNickNamePop" @getMessage="getNickName" />

    <!--联系方式编辑-->
    <TextInput v-model="showTelegram" title="Telgram" :initMsg="userInfo.telegram" :placeholder="$t('userDetail.telegramHolder')"
      @close="closeTelegramPop" @getMessage="getTelegram" />

    <!--微信-->
    <TextInput v-model="showWechat" :title="$t('userDetail.wechat')" :initMsg="userInfo.wechat" :placeholder="$t('userDetail.wechatHolder')"
      @close="closeWechatPop" @getMessage="getWechat" />

    <!--年龄-->
    <AgePicker v-model="showAge" @getSelected="getAge" @close="closeAgePop" />

    <!--身高体重-->
    <HwPicker v-model="showHeightWeight" @getSelected="getHeightWeight" @close="closeHeightWeightPop" />

    <!--自我介绍-->
    <TextInput v-model="showDescription" :title="$t('userDetail.selfDescription')" :initMsg="userInfo.description" inputType="textarea"
      :placeholder="$t('userDetail.selfDescriptionHolder')" :rows="5" :maxlength="150" @close="closeDescriptionPop"
      @getMessage="getDescription" />

    <!--行业-->
    <LabelPop v-model="showJob" :title="$t('userDetail.career')" :labels="jobLabels" :limit="1" @getSelectedList="getJob"
      @close="closeJobPop" />

    <!--气质类型-->
    <LabelPop v-model="showTemperament" :title="$t('userDetail.temperamentType')" :labels="temperamentLabels" :limit="3"
      @getSelectedList="getTemperament" @close="closeTemperamentPop" />

    <router-view class="child-router-view"></router-view>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
// import ChangeBg from '../../components/ChangeBg.vue';
import ChangeAvatar from '@/components/ChangeAvatar.vue';
import TextInput from '../../components/user/TextInput.vue'
import AgePicker from '../../components/user/AgePicker.vue'
import HwPicker from '../../components/user/HwPicker.vue'
import LabelPop from '../../components/user/LabelPop.vue'
import bus from '../../utils/bus'
import { addAgent } from '@/service/agent'
import { getLables } from '@/service/base'
export default {
  components: { Header, TextInput, AgePicker, HwPicker, LabelPop, ChangeAvatar, },
  data() {
    return {
      avatarList: [],
      userInfo: {},
      showNickName: false,
      showTelegram: false,
      showWechat: false,
      showAge: false,          // 年龄
      showHeightWeight: false, // 身高体重
      showLabel: false,        // 关于我标签
      showDescription: false,  // 自我介绍
      showJob: false,          // 行业
      showTemperament: false,  // 气质类型
      temperamentLabels: [],
      jobLabels: ['自由职业', '护士', '幼师', '医美'],  // 行业标签
      position: '', // 位置
    }
  },
  methods: {
    init() {
      this.getLabelList()
    },
    async getLabelList() {
      const resp = await getLables('sc_label_job')
      if (!resp.success) return
      this.jobLabels = resp.data

      const resp1 = await getLables('sc_label_temperament')
      if (!resp1.success) return
      this.temperamentLabels = resp1.data

    },
    setHeadPhoto(headPhoto) {
      this.userInfo.headPhoto = headPhoto;
    },
    async saveAgent() {
      if (!this.userInfo.headPhoto) {
        this.$toast(this.$t('agent.emptyAvatar'))
        return
      }
      if (!this.userInfo.nickname) {
        this.$toast(this.$t('agent.emptyNickname'))
        return
      }
      if (!this.userInfo.telegram && !this.userInfo.wechat) {
        this.$toast(this.$t('agent.tgAndWechatNotEmpty'))
        return
      }
      if (this.position === '' || !this.position.area.code) {
        this.$toast(this.$t('agent.emptyLocation'))
        return
      }
      if (this.position) {
        this.userInfo.location = this.position.area.code
      }
      if (this.userInfo.oftenList && this.userInfo.oftenList.length > 0) {
        this.userInfo.often = this.userInfo.oftenList.join()
      }
      const resp = await addAgent(this.userInfo)
      if (resp.code === 200) {
        this.$toast(this.$t('common.addSuccess'))
        this.userInfo = {}
        this.avatarList = []
        this.position = ''
        this.$router.go(-1)
      } else {
        this.$toast(this.$t('common.addFail'), resp.msg)
        console.log('添加资源失败,error:', resp.msg)
      }
    },
    editNickName() {
      this.showNickName = true
    },
    getNickName(val) {
      this.userInfo.nickname = val
    },
    editTelegram() {
      this.showTelegram = true
    },
    editWechat() {
      this.showWechat = true
    },
    editLabel() {
      this.showLabel = true
    },
    getTelegram(val) {
      this.userInfo.telegram = val
    },
    getWechat(val) {
      this.userInfo.wechat = val
    },
    eidtLocation(addressKey, areaLevel = 0, multiple = false, maxCount = 3) {
      const path = `/agentAdd/editAddress?addressKey=${addressKey}&areaLevel=${areaLevel}&multiple=${multiple}&maxCount=${maxCount}`
      this.$gbUtils.handleToPage(path)
    },
    editAge() {
      this.showAge = true
    },
    getAge(val) {
      this.userInfo.age = val.join()
    },
    editHeightWeight() {
      this.showHeightWeight = true
    },
    getHeightWeight(val) {
      this.userInfo.height = val[0].substring(0, 3)
      this.userInfo.weight = val[1].substring(0, 2)
    },
    getLabel(val) {
      this.userInfo.label = val.join()
    },
    eidtDescription() {
      this.showDescription = true
    },
    getDescription(val) {
      this.userInfo.description = val
    },
    editJob() {
      this.showJob = true
    },
    getJob(val) {
      this.userInfo.job = val[0]
    },
    editTemperament() {
      this.showTemperament = true
    },
    getTemperament(val) {
      this.userInfo.temperament = val.join()
    },
    closeNickNamePop() {
      this.showNickName = false
    },
    closeTelegramPop() {
      this.showTelegram = false
    },
    closeWechatPop() {
      this.showWechat = false
    },
    closeAgePop() {
      this.showAge = false
    },
    closeHeightWeightPop() {
      this.showHeightWeight = false
    },
    closeLabelPop() {
      this.showLabel = false
    },
    closeDescriptionPop() {
      this.showDescription = false
    },
    closeJobPop() {
      this.showJob = false
    },
    closeTemperamentPop() {
      this.showTemperament = false
    }
  },
  mounted() {
    this.init()
    document.getElementsByClassName('agent-wrap')[0].scrollTop = 0
    bus.$on('getSelectAreas', (key, data) => {
      if (key === 'often') { // 常出没城市后台没有存code,只存了name ，特殊处理
        let cityNames = []
        data.forEach(item => {
          cityNames.push(item.city.name)
        })
        this.userInfo.oftenList = cityNames
        this.userInfo.often = cityNames.join()
        this.$forceUpdate()
      } else {
        this.$data[key] = data
      }
    })
  }
}

</script>


<style lang="scss" scoped>
.agent-wrap {
  background: #14151e;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;

  .back-title {
    margin: 0 10px;
  }


  .agent-info-box {
    padding: 20px;
    width: 100%;

    >:first-child {
      border-top: 1px solid #3D3F51;
    }

    .info-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px 0;
      border-bottom: 1px solid #3D3F51;
      font-size: 14px;

      div {
        display: flex;
        align-items: center;
      }

      .item-right {
        div {
          font-size: 14px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
  }

  .btn {
    width: 200px;
    height: 38px;
    background: #8A73D0;
    border-radius: 4px;
    text-align: center;
    line-height: 38px;
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 150px;
  }

  .red {
    color: #EF08E4;
  }
}
</style>